import React from 'react';
import { Link } from 'gatsby';
import SEO from '../../../../components/SEO';
import Layout from '../../../../layouts/index';

const FAQ = props => (
  <Layout bodyClass="page-faq">
    <SEO title="CalorieBalance - FAQ" />
    <div className="container">
      <div className="row">
        <header className="col-12">
          <h1>CalorieBalance FAQ</h1>
        </header>
      </div>

      <div className="row">
        <div className="col-12">
          <h3>What is the caloric balance?</h3>
          <p>
            The caloric balance is your total burned energy (resting and active calories) minus your
            caloric intake from food and beverages. It can be either negative (more calories burnt
            than taken in) or positive (more calories taken in than burnt).
          </p>
          <hr />

          <h3>Where does the data come from?</h3>
          <p>
            All of the data comes from HealthKit. The app itself does neither track nor measure your
            caloric intake or expanditure. You need to have a device that measures and calculates
            your energy expenditure (like the Apple Watch) and you need to enter your dietary energy
            into a food tracking app of your choice that connects to HealthKit.
          </p>
          <hr />

          <h3>Why is there no data visible?</h3>
          <p>
            Please make sure that you have given CalorieBalance all neccessary permissions and that
            you have data to process. If you use an app to track your food, please make sure it has
            the capability and permission to write this data into HealthKit.
          </p>
          <hr />

          <h3>Why is the bar chart not complete?</h3>
          <p>
            lease make sure that you have given CalorieBalance all neccessary permissions. Otherwise
            it can not pull any data to process the bar graph.
          </p>
          <hr />

          <h3>Will CalorieBalance alter my HealthKit data in any way?</h3>
          <p>
            No. CalorieBalance will only ask you for reading permission of active, resting and
            dietary calories. It can not read any other values and it does not have the ability to
            rewrite your data.
          </p>
          <hr />

          <h3>The data is not progressing / updated</h3>
          <p>
            Data on the Apple Watch is not processed in real time. App background updates are
            limited to save battery power and ressources. Therefore, it will happen that
            complications and the app need to be opened to refresh. I am looking into improving this
            in the next release.
          </p>
          <hr />

          <h3>Why are the numbers different on the phone and the watch?</h3>
          <p>
            This has to do with background refreshes, too. The watch resyncs its HealthKit data
            periodically. Between the syncs, it&rsquo;s possible that the nummbers are slightly
            different.
          </p>
          <hr />

          <h3>Contact Us</h3>
          <p>
            If you have any questions, do not hesitate to
{' '}
<Link to="/contact">contact us</Link>
.
          </p>
        </div>
      </div>
    </div>
  </Layout>
);

export default FAQ;
